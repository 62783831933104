
  import { defineComponent, inject, onMounted, ref, nextTick} from "vue";
  import { useRouter, useRoute } from "vue-router";
  import $ from "jquery";
  import axios from "@/api/axios";
  import qs from "qs";
  import Toast from "@/components/UI/Toast";
  import Modal from "@/components/UI/Modal";
  import Loading from "@/components/UI/Loading/src/Loading.vue";
  import { parthrottle } from '@/utils/utils'
  export default defineComponent({
    name: "leadVoteView",
    components: {
      Loading
    },
    setup() {

      const page = ref(1)
      const isEnd = ref(false)

      const voteListA = ref<object>([])
      const voteListB = ref<object>([])
      const router = useRouter();
      const route = useRoute();
      //  展示用户信息
      const userInfo = inject("userInfo") as any;

      const loading = ref(false);
      const loadOption = {
        text: "正在加载...",
        color: "#df0024",
        textColor: "#df0024",
      };

      onMounted(function() {
        const token = localStorage.getItem("token");
        if (!token) {
          Modal({
            title: "温馨提示",
            content: "登录后才可以进行投票！",
            onClose: () => {
              router.push({
                name: "Login",
                query: {
                  redirect: router.currentRoute.value.fullPath,
                },
              });
            },
          });
          return false;
        }

        loading.value = true;
        axios
                .get("/M/Staff/votingStatisticsLeader2024")
                .then(res => {
                  loading.value = false;
                  if (res.data.success) {
                    const data = res.data.obj
                    voteListA.value = data.staffList101
                    voteListB.value = data.staffList102
                    nextTick(() => {
                      $('.home-wapper').css('padding-top', '0')
                      const height = Number($(window).height()) - Number($('header').outerHeight())-Number($('main').outerHeight()) + 'px'
                      $('.vote-list').css({'height': height})
                    })
                  } else {
                    Toast({
                      type: "error",
                      title: res.data.msg,
                      onClose: () => {
                        router.push({
                          name: "leadVote"
                        });
                      },
                    });
                  }
                })
                .catch(err => {
                  console.log(err);
                });
      })

      function refreshVote() {
        window.location.reload()
      }


      function back() {
        router.back();
      }

      return {
        userInfo,
        voteListA,
        voteListB,
        loading,
        loadOption,
        back,
        refreshVote
      }
    },
  });
